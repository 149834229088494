<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" md="12">
          <v-card width="100%">
            <v-card-title class="cyan darken-1">
              <span class="white--text">Buscar deudas por RUT</span>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text>
              <v-form ref="form" v-model="valid" lazy-validation>
                <br />
                <v-row>
                  <v-col cols="12" md="4"></v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="rut"
                      placeholder="Ejemplo: 12.345.678-9"
                      :rules="nameRules"
                      :counter="12"
                      prepend-icon="mdi-octagram"
                      label="RUT"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="4"></v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-divider></v-divider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="4"></v-col>

                  <v-col cols="12" md="4">
                    <div class="text-center">
                      <v-btn color="primary" @click="getDebtsByRut" outlined>
                        <v-icon>mdi-refresh-circle</v-icon> Buscar
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col cols="12" md="4"></v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-container>
        <vue-snotify></vue-snotify>
      </v-container>
    </v-container>
  </div>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";
import auth from "@/logic/auth";

export default {
  mixins: [SnotifyMixin],
  VueSnotify,

  data: () => ({
    rut: "",
    id: 0,
    description: "",
    menu1: false,

    nameRules: [
      (v) =>
        (v && v.length <= 190) || "El campo no debe exceder de 12 caracteres",
    ],
  }),

  methods: {
    cancel() {
      setTimeout(() => this.$router.push({ name: "home" }), 10);
    },
    getUserByToken(token) {
      console.log("entra");
      let request = {
        token: token,
      };
      axios
        .post("/register", request)
        .then((response) => {
          this.displayNotification("success", "Éxito", "ya logueo");

          let user = [
            token,
            response.data.name,
            response.data.rut,
            response.data.user_type,
          ];
          console.log(user);
          auth.setUserLogged(user);
          console.log(response.data);
          // setTimeout(() => this.$router.push({ name: "service" }), 4000);
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener cliente por token"
          );
          setTimeout(() => this.$router.push({ name: "service" }), 4000);
        });
    },
    getDebtsByRut() {
      console.log("busca rut");
      console.log(this.rut);

      let request = {
        rut: this.rut,
      };
      axios
        .post("/get-id-by-rut", request)
        .then((response) => {
          this.id = response.data;
          console.log("imprime id");
          console.log("imprime id");
          let route = "/my-debts/" + this.id;
          setTimeout(() => this.$router.push({ path: route }), 4000);
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "No existen deudas asociadas a este R.U.T"
          );
        });
    },
  },
};
</script>